
import { defineComponent, ref, onMounted, onActivated, computed } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import FilterDropdown from "./FilterDropdown.vue";
import productDefaultTable from "@/components/table/ProductDefault.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiSalesOrders } from "@/core/api";
import _ from "lodash";
import mixin from "@/mixins";
import fileDownload from "js-file-download";
import { useI18n } from "vue-i18n";
import { SalesOrderTable } from "@/core/directive/interface/salesOrder";
import { FilterOption, SortOption } from "@/core/directive/interface/common";
import { NumberOrString } from "@/core/directive/type/common";
import {
  getSalesOrderFulfillmentStatus,
  getSalesOrderPriorityLevelMap,
  getSalesOrderPriorityLevelSvgStyleMap,
  getSalesOrderStatusMap,
  getSalesOrderTypeOptions,
  getSettlementStatusMap,
} from "@/core/directive/function/salesOrder";
import store from "@/store";
import {
  SalesOrderNewStatus,
  SalesOrderPriorityLevel,
  SalesOrderSettlementStatus,
} from "@/core/directive/type/salesOrder";
import { useRoute } from "vue-router";
import {
  CommonArrayToString,
  formatDate, formatDateOriginTimeZone,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { Mutations } from "@/store/enums/StoreEnums";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import WindowResize from "@/components/layout/WindowResize.vue";

export default defineComponent({
  name: "double-sale-order-order-overview",
  components: {
    MBDatatablePlus,
    productDefaultTable,
    FilterDropdown,
    PermissionCommon,
    WindowResize,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { showServerErrorMsg } = mixin();

    const loading = ref(true);
    const tableData = ref<Array<SalesOrderTable>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const disabledImportOrders = ref<boolean>(false);
    const disabledExportSalesProfilling = ref<boolean>(false);
    const disabledExportOrderCombination = ref<boolean>(false);
    const disabledExportSalesOrderItemList = ref<boolean>(false);
    const disabledExportSalesFinancial = ref<boolean>(false);
    const disabledExport = ref<boolean>(false);
    const disabledExportSku = ref<boolean>(false);
    const disabledExportSettlementInvoiceReport = ref<boolean>(false);
    const disabledExportCrossCheckReport = ref<boolean>(false);
    const dialogVisible = ref<boolean>(false);
    const options = ref({
      status_value: [
        String(SalesOrderNewStatus.New),
        String(SalesOrderNewStatus.Submit),
        String(SalesOrderNewStatus.Verified),
        String(SalesOrderNewStatus.Processing),
        String(SalesOrderNewStatus.FulfillmentCompleted),
        String(SalesOrderNewStatus.OrderCompleted),
        String(SalesOrderNewStatus.Refunded),
      ],
    });
    const filterOptions = ref<Array<FilterOption>>([
      {
        field: "store_id",
        value: ["16", "25"],
        condition: "in",
      },
      {
        field: "status",
        value: options.value.status_value,
        condition: "in",
      },
    ]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "order_number", direction: "desc" },
    ]);
    const tableHeader = ref([
      {
        name: t("salesOrder.orderId"),
        key: "order_number",
        sortable: true,
      },
      {
        name: t("salesOrder.businessDateTime"),
        key: "business_date",
        sortable: true,
      },
      // {
      //   name: t("salesOrder.priority"),
      //   key: "is_priority",
      //   sortable: false,
      // },
      // {
      //   name: t("salesOrder.deliveryDate"),
      //   key: "delivery_date",
      //   sortable: false,
      // },
      {
        name: t("order.customer"),
        key: "customer",
        sortable: false,
      },
      {
        name: t("order.articles"),
        key: "articles",
        sortable: false,
      },
      {
        name: t("order.listQty"),
        key: "ordered_qty",
        sortable: true,
        align: "right",
      },
      {
        name: t("salesOrder.grandTotalBase"),
        key: "grand_total_base",
        sortable: true,
        align: "right",
      },
      {
        name: t("salesOrder.status"),
        key: "order_status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const init = () => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSalesOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    const openImport = () => {
      dialogVisible.value = true;
    };

    const closeImport = () => {
      dialogVisible.value = false;
    };

    const fileExportSalesProfilling = () => {
      if (disabledExportSalesProfilling.value) true;
      disabledExportSalesProfilling.value = true;
      ApiSalesOrders.exportSalesProfilling({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesProfilling.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Profilling.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesProfilling.value = false;
          console.log(error);
        });
    };

    const fileExportOrderCombination = () => {
      if (disabledExportOrderCombination.value) true;
      disabledExportOrderCombination.value = true;
      ApiSalesOrders.exportOrderCombination({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportOrderCombination.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Profilling.zip");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportOrderCombination.value = false;
          console.log(error);
        });
    };

    const fileExportSalesOrderItemList = () => {
      if (disabledExportSalesOrderItemList.value) true;
      disabledExportSalesOrderItemList.value = true;
      ApiSalesOrders.exportSalesOrderItemList({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesOrderItemList.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Details.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesOrderItemList.value = false;
          console.log(error);
        });
    };

    const fileExportSalesFinancial = () => {
      if (disabledExportSalesFinancial.value) true;
      disabledExportSalesFinancial.value = true;
      ApiSalesOrders.exportSalesFinancial({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSalesFinancial.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              fileDownload(data.data, "Sales Order Financial.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSalesOrderItemList.value = false;
          console.log(error);
        });
    };

    const fileExport = () => {
      if (disabledExport.value) true;
      disabledExport.value = true;
      ApiSalesOrders.exportDoubleSale({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExport.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Sales Order Report (Order ID).xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExport.value = false;
          console.log(error);
        });
    };

    const fileExportSku = () => {
      if (disabledExportSku.value) true;
      disabledExportSku.value = true;
      ApiSalesOrders.exportDoubleSaleSku({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSku.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Sales Order Report (SKU).xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSku.value = false;
          console.log(error);
        });
    };

    const fileExportSettlementInvoiceReport = () => {
      if (disabledExportSettlementInvoiceReport.value) true;
      disabledExportSettlementInvoiceReport.value = true;
      ApiSalesOrders.exportSettlementInvoiceReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportSettlementInvoiceReport.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Settlement Invoice Report.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportSettlementInvoiceReport.value = false;
          console.log(error);
        });
    };

    const fileExportCrossCheckReport = () => {
      if (disabledExportCrossCheckReport.value) true;
      disabledExportCrossCheckReport.value = true;
      ApiSalesOrders.exportCrossCheckReport({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
      })
        .then((data) => {
          disabledExportCrossCheckReport.value = false;
          // const disposition = data.headers["content-disposition"];
          // fileDownload(data.data, disposition.split("filename=")[1]);
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as string);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              fileDownload(data.data, "Cross-Check Report.xlsx");
            }
          };
          fileReader.readAsText(data.data);
        })
        .catch((error) => {
          disabledExportCrossCheckReport.value = false;
          console.log(error);
        });
    };

    const getSalesOrderList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiSalesOrders.getSalesOrderList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      dialogVisible.value = false;
      getSalesOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getSalesOrderList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const getLabel = (val, key) => {
      return options.value[key].find((item) => {
        return item.value == val;
      })?.label;
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSalesOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != "" && val[item] != null) {
          if (item == "settlement_status") {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "in",
            });
          } else if (item == "status") {
            filterOptions.value[1].value = val[item];
          } else if (item == "business_date_start") {
            filterArr.push({
              field: "business_date",
              value: val[item],
              condition: "gteq",
            });
          } else if (item == "business_date_end") {
            filterArr.push({
              field: "business_date",
              value: val[item],
              condition: "lteq",
            });
          } else if (
            item == "customer_id" ||
            item == "priority_level" ||
            item == "purchase_information" ||
            item == "brand_id"
          ) {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "eq",
            });
          } else {
            filterArr.push({
              field: item,
              value: val[item],
              condition: "like",
            });
          }
        }
      }
      filterOptions.value.splice(2, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
      getSalesOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getSalesOrderList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(2, filterOptions.value.length);
      filterOptions.value[1].value = options.value.status_value;
    };

    const debounceSearch = _.debounce(getSalesOrderList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const getOrderType = computed(() => {
      return (status: number) => {
        return getSalesOrderTypeOptions(status, t);
      };
    });

    const orderStatus = computed(() => {
      return (status: number) => {
        return getSalesOrderStatusMap(status, t)?.color;
      };
    });

    const fulfillmentStatus = computed(() => {
      return (status: NumberOrString) => {
        return getSalesOrderFulfillmentStatus(status, t);
      };
    });

    const settlementStatus = computed(() => {
      return (status: number) => {
        return getSettlementStatusMap(status, t)?.color;
      };
    });

    return {
      t,
      CommonArrayToString,
      SalesOrderPriorityLevel,
      SalesOrderSettlementStatus,
      formatDate,
      formatDateTime,
      formatDateOriginTimeZone,
      getSalesOrderPriorityLevelMap,
      getSalesOrderPriorityLevelSvgStyleMap,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledImportOrders,
      disabledExportSalesProfilling,
      disabledExportOrderCombination,
      disabledExportSalesOrderItemList,
      disabledExportSalesFinancial,
      disabledExport,
      disabledExportSku,
      disabledExportSettlementInvoiceReport,
      disabledExportCrossCheckReport,
      dialogVisible,
      options,
      getSalesOrderList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      getLabel,
      searchItems,
      handleFilter,
      handleFilterReset,
      updateList,
      removeSearch,
      getOrderType,
      orderStatus,
      fulfillmentStatus,
      settlementStatus,
      openImport,
      closeImport,
      fileExportSalesProfilling,
      fileExportOrderCombination,
      fileExportSalesOrderItemList,
      fileExportSalesFinancial,
      fileExport,
      fileExportSku,
      fileExportSettlementInvoiceReport,
      fileExportCrossCheckReport,
    };
  },
});
